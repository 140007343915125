// @flow
import { select, all, call, fork, put, takeEvery, cancel, delay } from 'redux-saga/effects';

import {
    urlsLoaded,
    urlsLoad,
    appRedirect,
    appMessageThrow,
} from '../actions';

import {fetchJSON} from "../../helpers/api";

/**
 * Watch load url request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchUrlsLoad(): any {
    yield takeEvery('URLS_LOAD', function*({ payload: { domain_id } }) {
        try {
            const state = yield select();

            const options = {
                search: {
                    page: state.Urls.pagination.page,
                    limit: state.Urls.pagination.limit,
                },
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            if (state.Urls.filters.languages) {
                options.search['languages'] = state.Urls.filters.languages;
            }

            if (state.Urls.filters.translation) {
                options.search['translation'] = state.Urls.filters.translation;
            }

            if (state.Urls.filters.original) {
                options.search['original'] = state.Urls.filters.original;
            }

            const response = yield call(fetchJSON, '/domains/'+domain_id+'/urls', options);

            yield put(urlsLoaded(response.data, response.pagination));
        } catch (error) {
            // Check if it fetch error where connection timed out
            console.warn(error);
            if (error instanceof DOMException) {
                const is_timed_out = error.message.includes('aborted') || error.message.includes('timeout');
                if (is_timed_out) {
                    yield put(appMessageThrow('Connection timed out, please try again', 'warning'));
                } else {
                    yield put(appRedirect('/error-500'));
                }
            } else {
                if (error.status_code === 404) {
                    yield put(appRedirect('/error-404'));
                } else if (
                    error.status_code === 504 ||
                    (
                        error.message && (
                            error.message.toLowerCase().includes('timeout') ||
                            error.message.toLowerCase().includes('timed out')
                        )
                    )
                ) {
                    yield put(appMessageThrow('Connection timed out, please try again', 'warning'));
                } else if (error.status_code === 500 && error.message && error.message.includes('execution time exceeded')) {
                    yield put(appMessageThrow('Connection timed out, please try again', 'warning'));
                } else {
                    yield put(appRedirect('/error-500'));
                }
            }
        }
    });
}

/**
 * Watch url deleted
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchUrlDeleted(): any {
    yield takeEvery('URL_DELETED', function*() {
        yield put(urlsLoad());
    });
}

/**
 * Watch urls filter change
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchUrlsChangeInput(): any {
    // Wait the user to stop typing before loading urls
    let taskDelayedUrlsLoad = null;
    yield takeEvery('URLS_CHANGE_INPUT', function* ({ payload: {domain_id}}){
        if (taskDelayedUrlsLoad !== null) {
            yield cancel(taskDelayedUrlsLoad);
        }
        taskDelayedUrlsLoad = yield fork(function* (){
            yield delay(500);
            yield put(urlsLoad(domain_id));
        });
    });
}

function* UrlsSaga(): any {
    yield all([fork(watchUrlsLoad), fork(watchUrlsChangeInput), fork(watchUrlDeleted)]);
}

export default UrlsSaga;
