// @flow
import { select, all, call, fork, put, takeEvery, cancel, delay } from 'redux-saga/effects';

import {
    translationsLoaded,
    translationsLoad,
    appRedirect,
    appMessageThrow
} from '../actions';

import {fetchJSON} from "../../helpers/api";

/**
 * Watch load translation request
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchTranslationsLoad(): any {
    yield takeEvery('TRANSLATIONS_LOAD', function*({ payload: { domain_id } }) {
        try {
            const state = yield select();

            const options = {
                search: {
                    page: state.Translations.pagination.page,
                    limit: state.Translations.pagination.limit,
                },
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            if (state.Translations.filters.languages) {
                options.search['languages'] = state.Translations.filters.languages;
            }

            if (state.Translations.filters.translation) {
                options.search['translation'] = state.Translations.filters.translation;
            }

            if (state.Translations.filters.original) {
                options.search['original'] = state.Translations.filters.original;
            }

            if (state.Translations.filters.edited) {
                options.search['edited'] = state.Translations.filters.edited;
            }

            if (state.Translations.filters.sortDirection) {
                options.search['sortDirection'] = state.Translations.filters.sortDirection;
            }

            const response = yield call(fetchJSON, '/domains/'+domain_id+'/translations', options);

            yield put(translationsLoaded(response.data, response.pagination));
        } catch (error) {
            // Check if it fetch error where connection timed out
            console.warn(error);
            if (error instanceof DOMException) {
                const is_timed_out = error.message.includes('aborted') || error.message.includes('timeout');
                if (is_timed_out) {
                    yield put(appMessageThrow('Connection timed out, please try again', 'warning'));
                } else {
                    yield put(appRedirect('/error-500'));
                }
            } else {
                if (error.status_code === 404) {
                    yield put(appRedirect('/error-404'));
                } else if (
                    error.status_code === 504 ||
                    (
                        error.message && (
                            error.message.toLowerCase().includes('timeout') ||
                            error.message.toLowerCase().includes('timed out')
                        )
                    )
                ) {
                    yield put(appMessageThrow('Connection timed out, please try again', 'warning'));
                } else if (error.status_code === 500 && error.message && error.message.includes('execution time exceeded')) {
                    yield put(appMessageThrow('Connection timed out, please try again', 'warning'));
                } else {
                    yield put(appRedirect('/error-500'));
                }
            }
        }
    });
}

/**
 * Watch translations filter change
 * @returns {IterableIterator<ForkEffect>}
 */
export function* watchTranslationsChangeInput(): any {
    // Wait the user to stop typing before loading translations
    let taskDelayedTranslationsLoad = null;
    yield takeEvery('TRANSLATIONS_CHANGE_INPUT', function* ({payload: {domain_id}}){
        if (taskDelayedTranslationsLoad !== null) {
            yield cancel(taskDelayedTranslationsLoad);
        }
        taskDelayedTranslationsLoad = yield fork(function* (){
            yield delay(500);
            yield put(translationsLoad(domain_id));
        });
    });
}

function* TranslationsSaga(): any {
    yield all([fork(watchTranslationsLoad), fork(watchTranslationsChangeInput)]);
}

export default TranslationsSaga;
