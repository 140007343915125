// @flow

import update from 'immutability-helper';
import type {ReduxActionType} from "../../flow/ReduxActionType";

const INITIAL_STATE = {
    account: {
        name: '',
        password: '',
        accounts: [],
        emailPreferences: {},
    },
    domainNotices: [],
    saving: false,
    loading: true,
};

const makeNestedObj = (path, value) => {
    const pieces = path.split('.');
    pieces.push(value);
    return pieces.reverse().reduce((carry, value) => ({[value]: carry}));
};
type State = { type?: string };

const Account = (state: State = INITIAL_STATE, action: ReduxActionType) => {
    switch (action.type) {
        case 'ACCOUNT_LOAD':
            return {...INITIAL_STATE};

        case 'ACCOUNT_LOADED':
            return {
                ...state,
                account: {...action.payload, password: ''},
                loading: false,
            };

        case 'ACCOUNT_CHANGE_INPUT':
            return update(state, {
                account: makeNestedObj(action.payload.name, {$set: action.payload.value})
            });

        case 'ACCOUNT_SAVE':
            return {...state, saving: true};

        case 'ACCOUNT_REQUEST_FAILED':
            return {...state, saving: false};

        case 'ACCOUNT_NOTICE_LOADED':
            return {...state, domainNotices: action.payload};

        default:
            return state;
    }
};

export default Account;
