// @flow
import cookieToken from '../../helpers/cookieToken';
import {route} from "../../helpers/common";
import {all, call, fork, put, take, takeEvery} from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    appLoad,
    appRedirect,
    appUserDisconnect,
    loginUserSuccess,
    loginUserFailed,
    registerUserSuccess,
    registerUserFailed,
    forgetPasswordSuccess,
    forgetPasswordFailed,
    resetPasswordSuccess,
    resetPasswordFailed,
    fallbackToCaptchaV2,
} from '../actions';


/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { email, password } }) {
    const options = {
        body: JSON.stringify({ email: email, password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/login/email', options, true);

        cookieToken.set(response.token);

        yield put(loginUserSuccess());
        yield put(appLoad());
    } catch (error) {
        let message = 'Unexpected error';
        if (error.message) {
            message = error.message;
        }
        yield put(loginUserFailed(message));
    }
}

/**
 * Login the user via third party services
 */
const makeThirdPartyLogin = (method) => function* () {
    const baseUrl = 'http' + (process.env.REACT_APP_USE_HTTPS==='1'?'s':'') + '://' +  process.env.REACT_APP_DOMAIN_NAME;

    try {
        const url = `${baseUrl}${process.env.REACT_APP_BASE_PATH}/login/${method}`;

        window.open(url, '_blank', 'width=450,height=600' );

        const response = yield call(() => {
            return new Promise((resolve)=>{
                window.addEventListener('message', (message) => {
                    if (message.origin !== baseUrl) {
                        return;
                    }
                    resolve(message.data);
                });
            });
        });

        if (response.error) {
            throw new Error(response.error);
        }
        cookieToken.set(response.token);

        yield put(appLoad());
    } catch (error) {
        let message = 'Unexpected error';
        if (error.message) {
            message = error.message;
        }
        yield put(loginUserFailed(message));
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        cookieToken.remove();
        localStorage.removeItem('user');

        yield put(appUserDisconnect());

        yield call(() => {
            history.push('/account/login');
        });
    } catch (error) {}
}

/**
 * Register the user
 */
function* register({ payload: { name, email, password, recaptchaToken, isV2 } }) {
    const options = {
        body: JSON.stringify({ name, email, password, recaptchaToken, v: isV2 ? 'v2' : 'v3' }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/login/register', options);

        cookieToken.set(response.token);

        yield put(registerUserSuccess(response));
        yield put(appLoad());
        yield take('APP_LOADED');
        yield put(appRedirect(route('domain.add')));
    } catch (error) {
        if (error.fallbackV2) {
            // Trigger V2 fallback
            yield put(fallbackToCaptchaV2());
            yield put(registerUserFailed('Automatic captcha validation failed, please do it manually'));
        } else if (error.message) {
            yield put(registerUserFailed(error.message));
        } else {
            yield put(registerUserFailed(error));
        }
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: {email, recaptchaToken, isV2} }) {
    const options = {
        body: JSON.stringify({ email: {email, recaptchaToken, v: isV2 ? 'v2' : 'v3'} }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/login/forget', options);
        yield put(forgetPasswordSuccess(response));
    } catch (error) {
        if (error.fallbackV2) {
            // Trigger V2 fallback
            yield put(fallbackToCaptchaV2());
            yield put(forgetPasswordFailed('Automatic captcha validation failed, please do it manually'));
        } else if (error.message) {
            yield put(forgetPasswordFailed(error.message));
        } else {
            yield put(forgetPasswordFailed('Unexpected error'));
        }
    }
}

/**
 * reset password
 */
function* resetPassword({ payload: { email, token, password } }) {
    const options = {
        body: JSON.stringify({email, token, password}),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/login/reset', options);
        yield put(resetPasswordSuccess(response));
    } catch (error) {
        let message = 'Unexpected error';

        if (error.message) {
            message = error.message;
        }

        if (error.errors) {
            const [firstError] = Object.values(error.errors);

            if (firstError) {
                [message] = firstError;
            }
        }

        yield put(resetPasswordFailed(message));
    }
}

export function* watchLoginUser(): any {
    yield takeEvery('LOGIN_USER', login);
}

export function* watchLoginUserGoogle(): any {
    yield takeEvery('LOGIN_USER_GOOGLE', makeThirdPartyLogin('google'));
}

export function* watchLoginUserMicrosoft(): any {
    yield takeEvery('LOGIN_USER_MICROSOFT', makeThirdPartyLogin('microsoft'));
}

export function* watchLogoutUser(): any {
    yield takeEvery('LOGOUT_USER', logout);
}

export function* watchRegisterUser(): any {
    yield takeEvery('REGISTER_USER', register);
}

export function* watchForgetPassword(): any {
    yield takeEvery('FORGET_PASSWORD', forgetPassword);
}

export function* watchResetPassword(): any {
    yield takeEvery('RESET_PASSWORD', resetPassword);
}

function* authSaga(): any {
    yield all([fork(watchLoginUser), fork(watchLoginUserGoogle), fork(watchLoginUserMicrosoft), fork(watchLogoutUser), fork(watchRegisterUser), fork(watchForgetPassword), fork(watchResetPassword)]);
}

export default authSaga;
