// @flow

import type {ReduxActionType} from "../../flow/ReduxActionType";

export const loginUser = (email: string, password: string): ReduxActionType => ({
    type: 'LOGIN_USER',
    payload: { email, password },
});

export const loginUserFailed = (error: string): ReduxActionType => ({
    type: 'LOGIN_USER_FAILED',
    payload: error,
});
export const loginUserSuccess = (): ReduxActionType => ({
    type: 'LOGIN_USER_SUCCESS',
    payload: {},
});

export const loginUserGoogle = (): ReduxActionType => ({
    type: 'LOGIN_USER_GOOGLE',
    payload: {},
});

export const loginUserMicrosoft = (): ReduxActionType => ({
    type: 'LOGIN_USER_MICROSOFT',
    payload: {},
});

export const registerUser = (name: string, email: string, password: string, recaptchaToken: string, isV2: boolean = false): ReduxActionType => ({
    type: 'REGISTER_USER',
    payload: { name, email, password, recaptchaToken, isV2 },
});

export const registerUserSuccess = (user: {}): ReduxActionType => ({
    type: 'REGISTER_USER_SUCCESS',
    payload: user,
});

export const registerUserFailed = (error: string): ReduxActionType => ({
    type: 'REGISTER_USER_FAILED',
    payload: error,
});

export const logoutUser = (history: any): ReduxActionType => ({
    type: 'LOGOUT_USER',
    payload: { history },
});

export const forgetPassword = (email: string, recaptchaToken: string, isV2: boolean = false): ReduxActionType => ({
    type: 'FORGET_PASSWORD',
    payload: { email, recaptchaToken, isV2 },
});

export const forgetPasswordSuccess = (passwordForgetStatus: string): ReduxActionType => ({
    type: 'FORGET_PASSWORD_SUCCESS',
    payload: passwordForgetStatus,
});

export const forgetPasswordFailed = (error: string): ReduxActionType => ({
    type: 'FORGET_PASSWORD_FAILED',
    payload: error,
});

export const resetPassword = (email: string, token: string, password: string): ReduxActionType => ({
    type: 'RESET_PASSWORD',
    payload: { email, token, password },
});

export const resetPasswordSuccess = (passwordResetStatus: string): ReduxActionType => ({
    type: 'RESET_PASSWORD_SUCCESS',
    payload: passwordResetStatus,
});

export const resetPasswordFailed = (error: string): ReduxActionType => ({
    type: 'RESET_PASSWORD_FAILED',
    payload: error,
});

export const clearAuthErrors = (): ReduxActionType => ({
    type: 'AUTH_CLEAR_ERRORS',
    payload: {},
});

export const fallbackToCaptchaV2 = (): ReduxActionType => ({
    type: 'AUTH_CAPTCHA_V2_NEEDED',
    payload: {},
});
