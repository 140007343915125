// @flow

import type {ReduxActionType} from "../../flow/ReduxActionType";

const INITIAL_STATE = {
    loading: false,
    error: null,
    captcha_v2: false,
    reset_password_error: null,
    reset_password_success: null,
    forget_password_error: null,
    forget_password_success: null
};

type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Auth = (state: State = INITIAL_STATE, action: ReduxActionType) => {
    switch (action.type) {
        case 'LOGIN_USER':
            return { ...state, loading: true };
        case 'LOGIN_USER_SUCCESS':
            return { ...state, user: action.payload, loading: false, error: null };
        case 'LOGIN_USER_FAILED':
            return { ...state, error: action.payload, loading: false };
        case 'REGISTER_USER':
            return { ...state, loading: true };
        case 'REGISTER_USER_SUCCESS':
            return { ...state, user: action.payload, loading: false, error: null };
        case 'REGISTER_USER_FAILED':
            return { ...state, error: action.payload, loading: false };
        case 'AUTH_CAPTCHA_V2_NEEDED':
            return { ...state, loading: false, error: null, captcha_v2: true };
        case 'LOGOUT_USER':
            return { ...state, user: null, error: null};
        case 'FORGET_PASSWORD':
            return { ...state, loading: true };
        case 'FORGET_PASSWORD_SUCCESS':
            return { ...state, loading: false, forget_password_error: null, forget_password_success: action.payload };
        case 'FORGET_PASSWORD_FAILED':
            return { ...state, loading: false, forget_password_error: action.payload, forget_password_success: null };
        case 'RESET_PASSWORD_SUCCESS':
            return { ...state, loading: false, reset_password_error: null, reset_password_success: action.payload };
        case 'RESET_PASSWORD_FAILED':
            return { ...state, loading: false, reset_password_error: action.payload, reset_password_success: null };
        case 'AUTH_CLEAR_ERRORS':
            return { ...state, loading: false, error: null, captcha_v2: false, reset_password_error: null};
        default:
            return { ...state };
    }
};

export default Auth;
